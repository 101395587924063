<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Countries</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table :loading="loading" loading-text="Loading... Please wait" :headers="headers" :items="countries" :search="search"></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    countries: [],
     headers: [
      { text: "Id", value: "id" },
      { text: "Code", value: "code" },
      { text: "Name", value: "name" }
    ],
  }),
  methods: {
     getCountries() {
      const self = this;
      this.loading = true;
       this.$store
        .dispatch("get", `/country`)
        .then(res => {
          self.countries = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getCountries();
  }
};
</script>